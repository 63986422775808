package tta.destinigo.talktoastro.shared_razorpay

data class PaymentGatewayArguments(
    val appName:String,
    val themeColor:String,
    val paymentUserName: String?,
    val paymentUserMobile: String? ,
    val paymentUserEmail: String?,
    val orderId: String,
    val razorpayKey: String? = "rzp_live_SOXGx6aTSzGwx2",// If this null than Live Razorpay key will use
    val paymentDescription: String = "Wallet Recharge of Talk to Astro",
    val appIcon:String
)