package tta.destinigo.talktoastro.shared_razorpay

import kotlinx.browser.document
import org.w3c.dom.HTMLInputElement
import org.w3c.dom.HTMLScriptElement
import kotlin.js.json


fun injectRazorpayForm(
    paymentArguments: PaymentGatewayArguments,
    onSuccessResponsePayment: (String, String, String) -> Unit,
    onErrorResponse: (Throwable) -> Unit
) {

    val form = document.createElement("form")
    form.setAttribute("id", "payment-form")
    val razorpayKey = paymentArguments.razorpayKey ?: "rzp_live_SOXGx6aTSzGwx2"

    val script = document.createElement("script") as HTMLScriptElement
    script.src = "https://checkout.razorpay.com/v1/checkout.js"

    val hiddenInput = document.createElement("input") as HTMLInputElement
    hiddenInput.type = "hidden"
    hiddenInput.setAttribute("custom", "Hidden Element")
    hiddenInput.name = "hidden"

    form.appendChild(script)
    document.body?.appendChild(form)

    script.onload = {
        try {
            // Razorpay options and callback
            val options = json(
                "key" to razorpayKey,  // Replace with your actual key
               // "key" to "rzp_live_SOXGx6aTSzGwx2",  // Replace with your actual key
                "name" to paymentArguments.appName,
                "description" to paymentArguments.paymentDescription,
                "image" to paymentArguments.appIcon,
                "order_id" to paymentArguments.orderId,
                "prefill" to json(
                    "name" to paymentArguments.paymentUserName,
                    "email" to paymentArguments.paymentUserEmail,
                    "contact" to paymentArguments.paymentUserMobile
                ),
                "handler" to { response: dynamic ->
                    try {
                        onSuccessResponsePayment.invoke(
                            response.razorpay_payment_id,
                            response.razorpay_order_id,
                            response.razorpay_signature
                        )
                        handlePaymentSuccess(
                            response.razorpay_payment_id,
                            response.razorpay_order_id,
                            response.razorpay_signature
                        )
                    } catch (e: Throwable) {
                        onErrorResponse.invoke(e)
                        console.error("Error in payment handler: ${e.message}")
                        handlePaymentError(e)
                    }
                },
                "theme" to json(
                    "color" to paymentArguments.themeColor
                ),
                "modal" to json(
                    "ondismiss" to {
                        console.log("Payment modal closed by the user.")
                        onErrorResponse.invoke(Exception("Payment modal closed by the user"))
                    }
                )
            )

            console.log("Opening Razorpay checkout") // Debug log
            // Invoke Razorpay checkout
            js("new Razorpay(options).open()")
        } catch (e: Throwable) {
            console.error("Error setting up Razorpay options or opening checkout: ${e.message}")
            onErrorResponse.invoke(e)
            handlePaymentError(e)
        }
    }

    script.addEventListener("error", {
        console.error("Error loading Razorpay script") // Debug log
        onErrorResponse.invoke(Exception("Error loading Razorpay script"))
        handlePaymentError(Exception("Error loading Razorpay script"))
    })
}

fun handlePaymentError(error: Throwable) {
    // Handle error (e.g., show a user-friendly message, log the error, etc.)
    console.error("Payment error: ${error.message}")
}

fun handlePaymentSuccess(paymentId: String, orderId: String, signature: String) {
    // Handle the payment success response in Kotlin
    console.log("Payment ID: $paymentId")
    console.log("Order ID: $orderId")
    console.log("Signature: $signature")
}


