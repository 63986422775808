@file:OptIn(org.jetbrains.compose.resources.InternalResourceApi::class)

package tta.destinigo.talktoastro.resources

import kotlin.OptIn
import org.jetbrains.compose.resources.DrawableResource

private object CommonMainDrawable0 {
  public val astrology: DrawableResource by 
      lazy { init_astrology() }

  public val astrology_new: DrawableResource by 
      lazy { init_astrology_new() }

  public val avatar_13: DrawableResource by 
      lazy { init_avatar_13() }

  public val baseline_add_comment_24: DrawableResource by 
      lazy { init_baseline_add_comment_24() }

  public val baseline_arrow_forward_ios_24: DrawableResource by 
      lazy { init_baseline_arrow_forward_ios_24() }

  public val baseline_auto_premium_24: DrawableResource by 
      lazy { init_baseline_auto_premium_24() }

  public val baseline_category_24: DrawableResource by 
      lazy { init_baseline_category_24() }

  public val baseline_language_24: DrawableResource by 
      lazy { init_baseline_language_24() }

  public val baseline_notifications_24: DrawableResource by 
      lazy { init_baseline_notifications_24() }

  public val baseline_person_4_24: DrawableResource by 
      lazy { init_baseline_person_4_24() }

  public val baseline_support_agent_24: DrawableResource by 
      lazy { init_baseline_support_agent_24() }

  public val baseline_thumb_up_24: DrawableResource by 
      lazy { init_baseline_thumb_up_24() }

  public val baseline_voice_chat_24: DrawableResource by 
      lazy { init_baseline_voice_chat_24() }

  public val calender_semi_small_black: DrawableResource by 
      lazy { init_calender_semi_small_black() }

  public val celebrity_background: DrawableResource by 
      lazy { init_celebrity_background() }

  public val certificate: DrawableResource by 
      lazy { init_certificate() }

  public val chat_icon_medium: DrawableResource by 
      lazy { init_chat_icon_medium() }

  public val coin_balance: DrawableResource by 
      lazy { init_coin_balance() }

  public val content_type_custom_order_icon: DrawableResource by 
      lazy { init_content_type_custom_order_icon() }

  public val courses: DrawableResource by 
      lazy { init_courses() }

  public val courses_new: DrawableResource by 
      lazy { init_courses_new() }

  public val date_icon: DrawableResource by 
      lazy { init_date_icon() }

  public val expert_details_baseline_auto_premium_24: DrawableResource by 
      lazy { init_expert_details_baseline_auto_premium_24() }

  public val expert_details_baseline_language_24: DrawableResource by 
      lazy { init_expert_details_baseline_language_24() }

  public val expert_details_baseline_notifications_24: DrawableResource by 
      lazy { init_expert_details_baseline_notifications_24() }

  public val expert_details_baseline_voice_chat_24: DrawableResource by 
      lazy { init_expert_details_baseline_voice_chat_24() }

  public val expert_details_emergency_icon: DrawableResource by 
      lazy { init_expert_details_emergency_icon() }

  public val expert_details_ic_filled_star: DrawableResource by 
      lazy { init_expert_details_ic_filled_star() }

  public val expert_details_ic_heart_primary_color: DrawableResource by 
      lazy { init_expert_details_ic_heart_primary_color() }

  public val expert_details_ic_heart_primary_color_fill: DrawableResource by 
      lazy { init_expert_details_ic_heart_primary_color_fill() }

  public val expert_details_round_sort_24: DrawableResource by 
      lazy { init_expert_details_round_sort_24() }

  public val expert_details_will_notify_icon: DrawableResource by 
      lazy { init_expert_details_will_notify_icon() }

  public val facebook1: DrawableResource by 
      lazy { init_facebook1() }

  public val facebook2: DrawableResource by 
      lazy { init_facebook2() }

  public val ic_clock_request: DrawableResource by 
      lazy { init_ic_clock_request() }

  public val ic_duration_chronic: DrawableResource by 
      lazy { init_ic_duration_chronic() }

  public val ic_filled_star: DrawableResource by 
      lazy { init_ic_filled_star() }

  public val ic_heart_primary_color: DrawableResource by 
      lazy { init_ic_heart_primary_color() }

  public val ic_heart_primary_color_fill: DrawableResource by 
      lazy { init_ic_heart_primary_color_fill() }

  public val ic_help_center: DrawableResource by 
      lazy { init_ic_help_center() }

  public val ic_next: DrawableResource by 
      lazy { init_ic_next() }

  public val ic_otp_screen: DrawableResource by 
      lazy { init_ic_otp_screen() }

  public val ic_person: DrawableResource by 
      lazy { init_ic_person() }

  public val ic_pricing: DrawableResource by 
      lazy { init_ic_pricing() }

  public val ic_privacy_policy: DrawableResource by 
      lazy { init_ic_privacy_policy() }

  public val ic_reload: DrawableResource by 
      lazy { init_ic_reload() }

  public val ic_sign_out: DrawableResource by 
      lazy { init_ic_sign_out() }

  public val ic_term_service: DrawableResource by 
      lazy { init_ic_term_service() }

  public val ic_wallet: DrawableResource by 
      lazy { init_ic_wallet() }

  public val ic_whatsapp: DrawableResource by 
      lazy { init_ic_whatsapp() }

  public val icon_logo: DrawableResource by 
      lazy { init_icon_logo() }

  public val image_loading_square: DrawableResource by 
      lazy { init_image_loading_square() }

  public val instagram1: DrawableResource by 
      lazy { init_instagram1() }

  public val instagram2: DrawableResource by 
      lazy { init_instagram2() }

  public val linkedin1: DrawableResource by 
      lazy { init_linkedin1() }

  public val linkedin2: DrawableResource by 
      lazy { init_linkedin2() }

  public val new_expert_background: DrawableResource by 
      lazy { init_new_expert_background() }

  public val post_banner: DrawableResource by 
      lazy { init_post_banner() }

  public val premium_background: DrawableResource by 
      lazy { init_premium_background() }

  public val profile_edit_icon: DrawableResource by 
      lazy { init_profile_edit_icon() }

  public val profile_icon_avtar: DrawableResource by 
      lazy { init_profile_icon_avtar() }

  public val purchase_histor_bag: DrawableResource by 
      lazy { init_purchase_histor_bag() }

  public val refer_earn_big_banner: DrawableResource by 
      lazy { init_refer_earn_big_banner() }

  public val refer_earn_img: DrawableResource by 
      lazy { init_refer_earn_img() }

  public val round_person_4_24: DrawableResource by 
      lazy { init_round_person_4_24() }

  public val round_sort_24: DrawableResource by 
      lazy { init_round_sort_24() }

  public val salesiq_gold_medal: DrawableResource by 
      lazy { init_salesiq_gold_medal() }

  public val salesiq_silver_medal: DrawableResource by 
      lazy { init_salesiq_silver_medal() }

  public val secure_payment: DrawableResource by 
      lazy { init_secure_payment() }

  public val share_icon_new: DrawableResource by 
      lazy { init_share_icon_new() }

  public val shop: DrawableResource by 
      lazy { init_shop() }

  public val shop_new: DrawableResource by 
      lazy { init_shop_new() }

  public val spirtual: DrawableResource by 
      lazy { init_spirtual() }

  public val spritual_new: DrawableResource by 
      lazy { init_spritual_new() }

  public val subscribe_icon: DrawableResource by 
      lazy { init_subscribe_icon() }

  public val toolbar_heart: DrawableResource by 
      lazy { init_toolbar_heart() }

  public val trending_background: DrawableResource by 
      lazy { init_trending_background() }

  public val verified_expert_astrologer: DrawableResource by 
      lazy { init_verified_expert_astrologer() }

  public val verify_expert: DrawableResource by 
      lazy { init_verify_expert() }

  public val wallet_coin_balance: DrawableResource by 
      lazy { init_wallet_coin_balance() }

  public val wallet_round_star_outline_24: DrawableResource by 
      lazy { init_wallet_round_star_outline_24() }

  public val weekend_days_icon: DrawableResource by 
      lazy { init_weekend_days_icon() }

  public val welcome_banner_one: DrawableResource by 
      lazy { init_welcome_banner_one() }

  public val welcome_banner_three: DrawableResource by 
      lazy { init_welcome_banner_three() }

  public val welcome_banner_two: DrawableResource by 
      lazy { init_welcome_banner_two() }

  public val will_notify_icon: DrawableResource by 
      lazy { init_will_notify_icon() }

  public val youtube1: DrawableResource by 
      lazy { init_youtube1() }

  public val youtube2: DrawableResource by 
      lazy { init_youtube2() }
}

public val Res.drawable.astrology: DrawableResource
  get() = CommonMainDrawable0.astrology

private fun init_astrology(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:astrology",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/tta.destinigo.talktoastro.resources/drawable/astrology.jpg", -1, -1),
    )
)

public val Res.drawable.astrology_new: DrawableResource
  get() = CommonMainDrawable0.astrology_new

private fun init_astrology_new(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:astrology_new",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/tta.destinigo.talktoastro.resources/drawable/astrology_new.jpg", -1, -1),
    )
)

public val Res.drawable.avatar_13: DrawableResource
  get() = CommonMainDrawable0.avatar_13

private fun init_avatar_13(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:avatar_13",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/tta.destinigo.talktoastro.resources/drawable/avatar_13.xml", -1, -1),
    )
)

public val Res.drawable.baseline_add_comment_24: DrawableResource
  get() = CommonMainDrawable0.baseline_add_comment_24

private fun init_baseline_add_comment_24(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:baseline_add_comment_24",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/tta.destinigo.talktoastro.resources/drawable/baseline_add_comment_24.xml", -1, -1),
    )
)

public val Res.drawable.baseline_arrow_forward_ios_24: DrawableResource
  get() = CommonMainDrawable0.baseline_arrow_forward_ios_24

private fun init_baseline_arrow_forward_ios_24(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:baseline_arrow_forward_ios_24",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/tta.destinigo.talktoastro.resources/drawable/baseline_arrow_forward_ios_24.xml", -1, -1),
    )
)

public val Res.drawable.baseline_auto_premium_24: DrawableResource
  get() = CommonMainDrawable0.baseline_auto_premium_24

private fun init_baseline_auto_premium_24(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:baseline_auto_premium_24",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/tta.destinigo.talktoastro.resources/drawable/baseline_auto_premium_24.xml", -1, -1),
    )
)

public val Res.drawable.baseline_category_24: DrawableResource
  get() = CommonMainDrawable0.baseline_category_24

private fun init_baseline_category_24(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:baseline_category_24",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/tta.destinigo.talktoastro.resources/drawable/baseline_category_24.xml", -1, -1),
    )
)

public val Res.drawable.baseline_language_24: DrawableResource
  get() = CommonMainDrawable0.baseline_language_24

private fun init_baseline_language_24(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:baseline_language_24",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/tta.destinigo.talktoastro.resources/drawable/baseline_language_24.xml", -1, -1),
    )
)

public val Res.drawable.baseline_notifications_24: DrawableResource
  get() = CommonMainDrawable0.baseline_notifications_24

private fun init_baseline_notifications_24(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:baseline_notifications_24",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/tta.destinigo.talktoastro.resources/drawable/baseline_notifications_24.xml", -1, -1),
    )
)

public val Res.drawable.baseline_person_4_24: DrawableResource
  get() = CommonMainDrawable0.baseline_person_4_24

private fun init_baseline_person_4_24(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:baseline_person_4_24",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/tta.destinigo.talktoastro.resources/drawable/baseline_person_4_24.xml", -1, -1),
    )
)

public val Res.drawable.baseline_support_agent_24: DrawableResource
  get() = CommonMainDrawable0.baseline_support_agent_24

private fun init_baseline_support_agent_24(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:baseline_support_agent_24",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/tta.destinigo.talktoastro.resources/drawable/baseline_support_agent_24.xml", -1, -1),
    )
)

public val Res.drawable.baseline_thumb_up_24: DrawableResource
  get() = CommonMainDrawable0.baseline_thumb_up_24

private fun init_baseline_thumb_up_24(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:baseline_thumb_up_24",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/tta.destinigo.talktoastro.resources/drawable/baseline_thumb_up_24.xml", -1, -1),
    )
)

public val Res.drawable.baseline_voice_chat_24: DrawableResource
  get() = CommonMainDrawable0.baseline_voice_chat_24

private fun init_baseline_voice_chat_24(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:baseline_voice_chat_24",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/tta.destinigo.talktoastro.resources/drawable/baseline_voice_chat_24.xml", -1, -1),
    )
)

public val Res.drawable.calender_semi_small_black: DrawableResource
  get() = CommonMainDrawable0.calender_semi_small_black

private fun init_calender_semi_small_black(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:calender_semi_small_black",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/tta.destinigo.talktoastro.resources/drawable/calender_semi_small_black.xml", -1, -1),
    )
)

public val Res.drawable.celebrity_background: DrawableResource
  get() = CommonMainDrawable0.celebrity_background

private fun init_celebrity_background(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:celebrity_background",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/tta.destinigo.talktoastro.resources/drawable/celebrity_background.xml", -1, -1),
    )
)

public val Res.drawable.certificate: DrawableResource
  get() = CommonMainDrawable0.certificate

private fun init_certificate(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:certificate",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/tta.destinigo.talktoastro.resources/drawable/certificate.png", -1, -1),
    )
)

public val Res.drawable.chat_icon_medium: DrawableResource
  get() = CommonMainDrawable0.chat_icon_medium

private fun init_chat_icon_medium(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:chat_icon_medium",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/tta.destinigo.talktoastro.resources/drawable/chat_icon_medium.xml", -1, -1),
    )
)

public val Res.drawable.coin_balance: DrawableResource
  get() = CommonMainDrawable0.coin_balance

private fun init_coin_balance(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:coin_balance",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/tta.destinigo.talktoastro.resources/drawable/coin_balance.xml", -1, -1),
    )
)

public val Res.drawable.content_type_custom_order_icon: DrawableResource
  get() = CommonMainDrawable0.content_type_custom_order_icon

private fun init_content_type_custom_order_icon(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:content_type_custom_order_icon",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/tta.destinigo.talktoastro.resources/drawable/content_type_custom_order_icon.xml", -1, -1),
    )
)

public val Res.drawable.courses: DrawableResource
  get() = CommonMainDrawable0.courses

private fun init_courses(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:courses",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/tta.destinigo.talktoastro.resources/drawable/courses.jpg", -1, -1),
    )
)

public val Res.drawable.courses_new: DrawableResource
  get() = CommonMainDrawable0.courses_new

private fun init_courses_new(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:courses_new",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/tta.destinigo.talktoastro.resources/drawable/courses_new.jpg", -1, -1),
    )
)

public val Res.drawable.date_icon: DrawableResource
  get() = CommonMainDrawable0.date_icon

private fun init_date_icon(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:date_icon",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/tta.destinigo.talktoastro.resources/drawable/date_icon.xml", -1, -1),
    )
)

public val Res.drawable.expert_details_baseline_auto_premium_24: DrawableResource
  get() = CommonMainDrawable0.expert_details_baseline_auto_premium_24

private fun init_expert_details_baseline_auto_premium_24(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:expert_details_baseline_auto_premium_24",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/tta.destinigo.talktoastro.resources/drawable/expert_details_baseline_auto_premium_24.xml", -1, -1),
    )
)

public val Res.drawable.expert_details_baseline_language_24: DrawableResource
  get() = CommonMainDrawable0.expert_details_baseline_language_24

private fun init_expert_details_baseline_language_24(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:expert_details_baseline_language_24",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/tta.destinigo.talktoastro.resources/drawable/expert_details_baseline_language_24.xml", -1, -1),
    )
)

public val Res.drawable.expert_details_baseline_notifications_24: DrawableResource
  get() = CommonMainDrawable0.expert_details_baseline_notifications_24

private fun init_expert_details_baseline_notifications_24(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:expert_details_baseline_notifications_24",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/tta.destinigo.talktoastro.resources/drawable/expert_details_baseline_notifications_24.xml", -1, -1),
    )
)

public val Res.drawable.expert_details_baseline_voice_chat_24: DrawableResource
  get() = CommonMainDrawable0.expert_details_baseline_voice_chat_24

private fun init_expert_details_baseline_voice_chat_24(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:expert_details_baseline_voice_chat_24",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/tta.destinigo.talktoastro.resources/drawable/expert_details_baseline_voice_chat_24.xml", -1, -1),
    )
)

public val Res.drawable.expert_details_emergency_icon: DrawableResource
  get() = CommonMainDrawable0.expert_details_emergency_icon

private fun init_expert_details_emergency_icon(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:expert_details_emergency_icon",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/tta.destinigo.talktoastro.resources/drawable/expert_details_emergency_icon.xml", -1, -1),
    )
)

public val Res.drawable.expert_details_ic_filled_star: DrawableResource
  get() = CommonMainDrawable0.expert_details_ic_filled_star

private fun init_expert_details_ic_filled_star(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:expert_details_ic_filled_star",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/tta.destinigo.talktoastro.resources/drawable/expert_details_ic_filled_star.xml", -1, -1),
    )
)

public val Res.drawable.expert_details_ic_heart_primary_color: DrawableResource
  get() = CommonMainDrawable0.expert_details_ic_heart_primary_color

private fun init_expert_details_ic_heart_primary_color(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:expert_details_ic_heart_primary_color",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/tta.destinigo.talktoastro.resources/drawable/expert_details_ic_heart_primary_color.xml", -1, -1),
    )
)

public val Res.drawable.expert_details_ic_heart_primary_color_fill: DrawableResource
  get() = CommonMainDrawable0.expert_details_ic_heart_primary_color_fill

private fun init_expert_details_ic_heart_primary_color_fill(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:expert_details_ic_heart_primary_color_fill",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/tta.destinigo.talktoastro.resources/drawable/expert_details_ic_heart_primary_color_fill.xml", -1, -1),
    )
)

public val Res.drawable.expert_details_round_sort_24: DrawableResource
  get() = CommonMainDrawable0.expert_details_round_sort_24

private fun init_expert_details_round_sort_24(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:expert_details_round_sort_24",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/tta.destinigo.talktoastro.resources/drawable/expert_details_round_sort_24.xml", -1, -1),
    )
)

public val Res.drawable.expert_details_will_notify_icon: DrawableResource
  get() = CommonMainDrawable0.expert_details_will_notify_icon

private fun init_expert_details_will_notify_icon(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:expert_details_will_notify_icon",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/tta.destinigo.talktoastro.resources/drawable/expert_details_will_notify_icon.xml", -1, -1),
    )
)

public val Res.drawable.facebook1: DrawableResource
  get() = CommonMainDrawable0.facebook1

private fun init_facebook1(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:facebook1",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/tta.destinigo.talktoastro.resources/drawable/facebook1.png", -1, -1),
    )
)

public val Res.drawable.facebook2: DrawableResource
  get() = CommonMainDrawable0.facebook2

private fun init_facebook2(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:facebook2",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/tta.destinigo.talktoastro.resources/drawable/facebook2.png", -1, -1),
    )
)

public val Res.drawable.ic_clock_request: DrawableResource
  get() = CommonMainDrawable0.ic_clock_request

private fun init_ic_clock_request(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:ic_clock_request",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/tta.destinigo.talktoastro.resources/drawable/ic_clock_request.xml", -1, -1),
    )
)

public val Res.drawable.ic_duration_chronic: DrawableResource
  get() = CommonMainDrawable0.ic_duration_chronic

private fun init_ic_duration_chronic(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:ic_duration_chronic",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/tta.destinigo.talktoastro.resources/drawable/ic_duration_chronic.xml", -1, -1),
    )
)

public val Res.drawable.ic_filled_star: DrawableResource
  get() = CommonMainDrawable0.ic_filled_star

private fun init_ic_filled_star(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:ic_filled_star",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/tta.destinigo.talktoastro.resources/drawable/ic_filled_star.xml", -1, -1),
    )
)

public val Res.drawable.ic_heart_primary_color: DrawableResource
  get() = CommonMainDrawable0.ic_heart_primary_color

private fun init_ic_heart_primary_color(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:ic_heart_primary_color",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/tta.destinigo.talktoastro.resources/drawable/ic_heart_primary_color.xml", -1, -1),
    )
)

public val Res.drawable.ic_heart_primary_color_fill: DrawableResource
  get() = CommonMainDrawable0.ic_heart_primary_color_fill

private fun init_ic_heart_primary_color_fill(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:ic_heart_primary_color_fill",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/tta.destinigo.talktoastro.resources/drawable/ic_heart_primary_color_fill.xml", -1, -1),
    )
)

public val Res.drawable.ic_help_center: DrawableResource
  get() = CommonMainDrawable0.ic_help_center

private fun init_ic_help_center(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:ic_help_center",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/tta.destinigo.talktoastro.resources/drawable/ic_help_center.xml", -1, -1),
    )
)

public val Res.drawable.ic_next: DrawableResource
  get() = CommonMainDrawable0.ic_next

private fun init_ic_next(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:ic_next",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/tta.destinigo.talktoastro.resources/drawable/ic_next.xml", -1, -1),
    )
)

public val Res.drawable.ic_otp_screen: DrawableResource
  get() = CommonMainDrawable0.ic_otp_screen

private fun init_ic_otp_screen(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:ic_otp_screen",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/tta.destinigo.talktoastro.resources/drawable/ic_otp_screen.xml", -1, -1),
    )
)

public val Res.drawable.ic_person: DrawableResource
  get() = CommonMainDrawable0.ic_person

private fun init_ic_person(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:ic_person",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/tta.destinigo.talktoastro.resources/drawable/ic_person.xml", -1, -1),
    )
)

public val Res.drawable.ic_pricing: DrawableResource
  get() = CommonMainDrawable0.ic_pricing

private fun init_ic_pricing(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:ic_pricing",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/tta.destinigo.talktoastro.resources/drawable/ic_pricing.xml", -1, -1),
    )
)

public val Res.drawable.ic_privacy_policy: DrawableResource
  get() = CommonMainDrawable0.ic_privacy_policy

private fun init_ic_privacy_policy(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:ic_privacy_policy",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/tta.destinigo.talktoastro.resources/drawable/ic_privacy_policy.xml", -1, -1),
    )
)

public val Res.drawable.ic_reload: DrawableResource
  get() = CommonMainDrawable0.ic_reload

private fun init_ic_reload(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:ic_reload",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/tta.destinigo.talktoastro.resources/drawable/ic_reload.xml", -1, -1),
    )
)

public val Res.drawable.ic_sign_out: DrawableResource
  get() = CommonMainDrawable0.ic_sign_out

private fun init_ic_sign_out(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:ic_sign_out",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/tta.destinigo.talktoastro.resources/drawable/ic_sign_out.xml", -1, -1),
    )
)

public val Res.drawable.ic_term_service: DrawableResource
  get() = CommonMainDrawable0.ic_term_service

private fun init_ic_term_service(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:ic_term_service",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/tta.destinigo.talktoastro.resources/drawable/ic_term_service.xml", -1, -1),
    )
)

public val Res.drawable.ic_wallet: DrawableResource
  get() = CommonMainDrawable0.ic_wallet

private fun init_ic_wallet(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:ic_wallet",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/tta.destinigo.talktoastro.resources/drawable/ic_wallet.xml", -1, -1),
    )
)

public val Res.drawable.ic_whatsapp: DrawableResource
  get() = CommonMainDrawable0.ic_whatsapp

private fun init_ic_whatsapp(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:ic_whatsapp",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/tta.destinigo.talktoastro.resources/drawable/ic_whatsapp.xml", -1, -1),
    )
)

public val Res.drawable.icon_logo: DrawableResource
  get() = CommonMainDrawable0.icon_logo

private fun init_icon_logo(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:icon_logo",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/tta.destinigo.talktoastro.resources/drawable/icon_logo.png", -1, -1),
    )
)

public val Res.drawable.image_loading_square: DrawableResource
  get() = CommonMainDrawable0.image_loading_square

private fun init_image_loading_square(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:image_loading_square",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/tta.destinigo.talktoastro.resources/drawable/image_loading_square.xml", -1, -1),
    )
)

public val Res.drawable.instagram1: DrawableResource
  get() = CommonMainDrawable0.instagram1

private fun init_instagram1(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:instagram1",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/tta.destinigo.talktoastro.resources/drawable/instagram1.png", -1, -1),
    )
)

public val Res.drawable.instagram2: DrawableResource
  get() = CommonMainDrawable0.instagram2

private fun init_instagram2(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:instagram2",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/tta.destinigo.talktoastro.resources/drawable/instagram2.png", -1, -1),
    )
)

public val Res.drawable.linkedin1: DrawableResource
  get() = CommonMainDrawable0.linkedin1

private fun init_linkedin1(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:linkedin1",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/tta.destinigo.talktoastro.resources/drawable/linkedin1.png", -1, -1),
    )
)

public val Res.drawable.linkedin2: DrawableResource
  get() = CommonMainDrawable0.linkedin2

private fun init_linkedin2(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:linkedin2",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/tta.destinigo.talktoastro.resources/drawable/linkedin2.png", -1, -1),
    )
)

public val Res.drawable.new_expert_background: DrawableResource
  get() = CommonMainDrawable0.new_expert_background

private fun init_new_expert_background(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:new_expert_background",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/tta.destinigo.talktoastro.resources/drawable/new_expert_background.xml", -1, -1),
    )
)

public val Res.drawable.post_banner: DrawableResource
  get() = CommonMainDrawable0.post_banner

private fun init_post_banner(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:post_banner",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/tta.destinigo.talktoastro.resources/drawable/post_banner.png", -1, -1),
    )
)

public val Res.drawable.premium_background: DrawableResource
  get() = CommonMainDrawable0.premium_background

private fun init_premium_background(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:premium_background",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/tta.destinigo.talktoastro.resources/drawable/premium_background.xml", -1, -1),
    )
)

public val Res.drawable.profile_edit_icon: DrawableResource
  get() = CommonMainDrawable0.profile_edit_icon

private fun init_profile_edit_icon(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:profile_edit_icon",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/tta.destinigo.talktoastro.resources/drawable/profile_edit_icon.xml", -1, -1),
    )
)

public val Res.drawable.profile_icon_avtar: DrawableResource
  get() = CommonMainDrawable0.profile_icon_avtar

private fun init_profile_icon_avtar(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:profile_icon_avtar",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/tta.destinigo.talktoastro.resources/drawable/profile_icon_avtar.xml", -1, -1),
    )
)

public val Res.drawable.purchase_histor_bag: DrawableResource
  get() = CommonMainDrawable0.purchase_histor_bag

private fun init_purchase_histor_bag(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:purchase_histor_bag",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/tta.destinigo.talktoastro.resources/drawable/purchase_histor_bag.xml", -1, -1),
    )
)

public val Res.drawable.refer_earn_big_banner: DrawableResource
  get() = CommonMainDrawable0.refer_earn_big_banner

private fun init_refer_earn_big_banner(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:refer_earn_big_banner",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/tta.destinigo.talktoastro.resources/drawable/refer_earn_big_banner.jpg", -1, -1),
    )
)

public val Res.drawable.refer_earn_img: DrawableResource
  get() = CommonMainDrawable0.refer_earn_img

private fun init_refer_earn_img(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:refer_earn_img",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/tta.destinigo.talktoastro.resources/drawable/refer_earn_img.png", -1, -1),
    )
)

public val Res.drawable.round_person_4_24: DrawableResource
  get() = CommonMainDrawable0.round_person_4_24

private fun init_round_person_4_24(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:round_person_4_24",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/tta.destinigo.talktoastro.resources/drawable/round_person_4_24.xml", -1, -1),
    )
)

public val Res.drawable.round_sort_24: DrawableResource
  get() = CommonMainDrawable0.round_sort_24

private fun init_round_sort_24(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:round_sort_24",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/tta.destinigo.talktoastro.resources/drawable/round_sort_24.xml", -1, -1),
    )
)

public val Res.drawable.salesiq_gold_medal: DrawableResource
  get() = CommonMainDrawable0.salesiq_gold_medal

private fun init_salesiq_gold_medal(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:salesiq_gold_medal",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/tta.destinigo.talktoastro.resources/drawable/salesiq_gold_medal.png", -1, -1),
    )
)

public val Res.drawable.salesiq_silver_medal: DrawableResource
  get() = CommonMainDrawable0.salesiq_silver_medal

private fun init_salesiq_silver_medal(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:salesiq_silver_medal",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/tta.destinigo.talktoastro.resources/drawable/salesiq_silver_medal.png", -1, -1),
    )
)

public val Res.drawable.secure_payment: DrawableResource
  get() = CommonMainDrawable0.secure_payment

private fun init_secure_payment(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:secure_payment",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/tta.destinigo.talktoastro.resources/drawable/secure_payment.png", -1, -1),
    )
)

public val Res.drawable.share_icon_new: DrawableResource
  get() = CommonMainDrawable0.share_icon_new

private fun init_share_icon_new(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:share_icon_new",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/tta.destinigo.talktoastro.resources/drawable/share_icon_new.xml", -1, -1),
    )
)

public val Res.drawable.shop: DrawableResource
  get() = CommonMainDrawable0.shop

private fun init_shop(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:shop",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/tta.destinigo.talktoastro.resources/drawable/shop.jpg", -1, -1),
    )
)

public val Res.drawable.shop_new: DrawableResource
  get() = CommonMainDrawable0.shop_new

private fun init_shop_new(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:shop_new",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/tta.destinigo.talktoastro.resources/drawable/shop_new.jpg", -1, -1),
    )
)

public val Res.drawable.spirtual: DrawableResource
  get() = CommonMainDrawable0.spirtual

private fun init_spirtual(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:spirtual",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/tta.destinigo.talktoastro.resources/drawable/spirtual.jpg", -1, -1),
    )
)

public val Res.drawable.spritual_new: DrawableResource
  get() = CommonMainDrawable0.spritual_new

private fun init_spritual_new(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:spritual_new",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/tta.destinigo.talktoastro.resources/drawable/spritual_new.jpg", -1, -1),
    )
)

public val Res.drawable.subscribe_icon: DrawableResource
  get() = CommonMainDrawable0.subscribe_icon

private fun init_subscribe_icon(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:subscribe_icon",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/tta.destinigo.talktoastro.resources/drawable/subscribe_icon.xml", -1, -1),
    )
)

public val Res.drawable.toolbar_heart: DrawableResource
  get() = CommonMainDrawable0.toolbar_heart

private fun init_toolbar_heart(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:toolbar_heart",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/tta.destinigo.talktoastro.resources/drawable/toolbar_heart.xml", -1, -1),
    )
)

public val Res.drawable.trending_background: DrawableResource
  get() = CommonMainDrawable0.trending_background

private fun init_trending_background(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:trending_background",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/tta.destinigo.talktoastro.resources/drawable/trending_background.xml", -1, -1),
    )
)

public val Res.drawable.verified_expert_astrologer: DrawableResource
  get() = CommonMainDrawable0.verified_expert_astrologer

private fun init_verified_expert_astrologer(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:verified_expert_astrologer",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/tta.destinigo.talktoastro.resources/drawable/verified_expert_astrologer.png", -1, -1),
    )
)

public val Res.drawable.verify_expert: DrawableResource
  get() = CommonMainDrawable0.verify_expert

private fun init_verify_expert(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:verify_expert",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/tta.destinigo.talktoastro.resources/drawable/verify_expert.png", -1, -1),
    )
)

public val Res.drawable.wallet_coin_balance: DrawableResource
  get() = CommonMainDrawable0.wallet_coin_balance

private fun init_wallet_coin_balance(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:wallet_coin_balance",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/tta.destinigo.talktoastro.resources/drawable/wallet_coin_balance.xml", -1, -1),
    )
)

public val Res.drawable.wallet_round_star_outline_24: DrawableResource
  get() = CommonMainDrawable0.wallet_round_star_outline_24

private fun init_wallet_round_star_outline_24(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:wallet_round_star_outline_24",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/tta.destinigo.talktoastro.resources/drawable/wallet_round_star_outline_24.xml", -1, -1),
    )
)

public val Res.drawable.weekend_days_icon: DrawableResource
  get() = CommonMainDrawable0.weekend_days_icon

private fun init_weekend_days_icon(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:weekend_days_icon",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/tta.destinigo.talktoastro.resources/drawable/weekend_days_icon.xml", -1, -1),
    )
)

public val Res.drawable.welcome_banner_one: DrawableResource
  get() = CommonMainDrawable0.welcome_banner_one

private fun init_welcome_banner_one(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:welcome_banner_one",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/tta.destinigo.talktoastro.resources/drawable/welcome_banner_one.webp", -1, -1),
    )
)

public val Res.drawable.welcome_banner_three: DrawableResource
  get() = CommonMainDrawable0.welcome_banner_three

private fun init_welcome_banner_three(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:welcome_banner_three",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/tta.destinigo.talktoastro.resources/drawable/welcome_banner_three.webp", -1, -1),
    )
)

public val Res.drawable.welcome_banner_two: DrawableResource
  get() = CommonMainDrawable0.welcome_banner_two

private fun init_welcome_banner_two(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:welcome_banner_two",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/tta.destinigo.talktoastro.resources/drawable/welcome_banner_two.webp", -1, -1),
    )
)

public val Res.drawable.will_notify_icon: DrawableResource
  get() = CommonMainDrawable0.will_notify_icon

private fun init_will_notify_icon(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:will_notify_icon",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/tta.destinigo.talktoastro.resources/drawable/will_notify_icon.xml", -1, -1),
    )
)

public val Res.drawable.youtube1: DrawableResource
  get() = CommonMainDrawable0.youtube1

private fun init_youtube1(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:youtube1",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/tta.destinigo.talktoastro.resources/drawable/youtube1.png", -1, -1),
    )
)

public val Res.drawable.youtube2: DrawableResource
  get() = CommonMainDrawable0.youtube2

private fun init_youtube2(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:youtube2",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/tta.destinigo.talktoastro.resources/drawable/youtube2.png", -1, -1),
    )
)
