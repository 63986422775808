@file:OptIn(org.jetbrains.compose.resources.InternalResourceApi::class)

package tta.destinigo.talktoastro.resources

import kotlin.OptIn
import org.jetbrains.compose.resources.FontResource

private object CommonMainFont0 {
  public val NotoColorEmoji_Regular: FontResource by 
      lazy { init_NotoColorEmoji_Regular() }

  public val NotoSansDevanagari_SemiCondensed_Regular: FontResource by 
      lazy { init_NotoSansDevanagari_SemiCondensed_Regular() }

  public val roboto_regular: FontResource by 
      lazy { init_roboto_regular() }
}

public val Res.font.NotoColorEmoji_Regular: FontResource
  get() = CommonMainFont0.NotoColorEmoji_Regular

private fun init_NotoColorEmoji_Regular(): FontResource =
    org.jetbrains.compose.resources.FontResource(
  "font:NotoColorEmoji_Regular",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/tta.destinigo.talktoastro.resources/font/NotoColorEmoji-Regular.ttf", -1, -1),
    )
)

public val Res.font.NotoSansDevanagari_SemiCondensed_Regular: FontResource
  get() = CommonMainFont0.NotoSansDevanagari_SemiCondensed_Regular

private fun init_NotoSansDevanagari_SemiCondensed_Regular(): FontResource =
    org.jetbrains.compose.resources.FontResource(
  "font:NotoSansDevanagari_SemiCondensed_Regular",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/tta.destinigo.talktoastro.resources/font/NotoSansDevanagari_SemiCondensed-Regular.ttf", -1, -1),
    )
)

public val Res.font.roboto_regular: FontResource
  get() = CommonMainFont0.roboto_regular

private fun init_roboto_regular(): FontResource = org.jetbrains.compose.resources.FontResource(
  "font:roboto_regular",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/tta.destinigo.talktoastro.resources/font/roboto_regular.ttf", -1, -1),
    )
)
