package tta.destinigo.talktoastro.shared_razorpay

actual fun startPayment(
    paymentGatewayArguments: PaymentGatewayArguments,
    onPaymentSuccess: (String, String, String) -> Unit,
    onPaymentFailed: (Throwable) -> Unit
) {
    injectRazorpayForm(
        paymentArguments = paymentGatewayArguments,
        onSuccessResponsePayment = onPaymentSuccess,
        onErrorResponse = onPaymentFailed
    )
}